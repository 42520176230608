<template>
  <div>
    <a title="Enabled" v-if="row['enabled'] === true" @click="toggle(row)" data-type="org_user_activate">
      <v-icon color="green" left>mdi-toggle-switch</v-icon>
    </a>
    <a title="Disabled" v-if="row['enabled'] === false" @click="toggle(row)" data-type="org_user_inActivate">
      <v-icon left>mdi-toggle-switch-off</v-icon>
    </a>
  </div>
</template>
<script>
export default {
  methods: {
    toggle(row) {
      let options = {
        function: "toggleRowButton",
        id: row.id,
        enabled: row.enabled,
        dataType: "json",
        requestType: "ajax"
      };
      let caller = this;
      this.frameworkAxiosRequest({
        method: 'POST',
        url: 'serve.php?f=administration&f2=alertConfiguration',
        data: options,
      }).then(function (response) {
        caller.$set(caller.result, "headerExtraInfo", response.data.result.headerExtraInfo);
        caller.updateTable(response.data.result.table, null);
        caller.$notify({
          group: "foo",
          text: "Successfully updated",
          duration: 2000,
          type: "success",
          speed: 600,
        });
        row.enabled = !row.enabled;
      }).catch(function () {
      });
    },
  },
  data: function () {
    return {};
  },
  props: ["row", "value", "valueKey", "currentTable", "result"],
};
</script>